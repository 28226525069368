import React, { FC } from 'react';
import styles from './ActiveAvatar.module.scss';
import {Avatar} from "@mui/material";
import avatar from "../../public/IMG_1233.jpg";

interface ActiveAvatarProps {}

const ActiveAvatar: FC<ActiveAvatarProps> = () => (
  <div className={styles.ActiveAvatar} data-testid="ActiveAvatar">
      <Avatar alt="Lukasz Rzeszotarski" src={avatar} sx={{ width: 100, height: 100, margin: "auto" }} />
  </div>
);

export default ActiveAvatar;
